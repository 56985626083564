import styles from "./StackItem.module.css";

const StackItem = ({ src, alt, comment, closeNavBar }) => {
    return (
        <div onClick={closeNavBar} className={`${styles.card} ${styles.animation}`}>
            <img className={styles.icon1} src={src} alt={alt}></img>
            <div className={styles["content"]}>
                <div className={styles["comment-block"]}>
                    <p className={styles["comment"]}>{comment}</p>
                </div>
            </div>
        </div>
    );
};

export default StackItem;

import styles from "./CodeStrip.module.css";

const CodeStrip = () => {
    return (
        <div className={styles["header-context"]}>
            <p>
                .header-context p import styles from './HeaderContent module.css'; [dataState, setDataState] =
                useState;(false); users.map((user)= user.id === key && setDataState(true))
            </p>
        </div>
    );
};

export default CodeStrip;

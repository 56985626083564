import styles from "./ProjectsPage.module.css";
import Background from "../main/Background";
import NavBar from "../main/NavBar";
import Project from "./Project";

import annaSrc from "../../img/Annawebsite.webp";
import myFirtWS from "../../img/mywebsite.webp";
import mySecondWS from "../../img/Mywebsite2.webp";
import strategy from "../../img/strategy-game.webp";
import advertisement from "../../img/joan.webp";
import lostify from "../../img/lostify.webp";

const dataProjects = [
    {
        src: annaSrc,
        alt: "web site logo",
        href: "https://tvoypermanent.ru/",
        href2: "https://github.com/NaumenkoN/tvoypermanent",
        header: "Master of Beauty",
        comment: "Made using vanilla JS. Responsive for 80% devices.",
    },
    {
        src: myFirtWS,
        alt: "web site logo",
        href: "https://toseeworld.net/",
        href2: "https://github.com/NaumenkoN/toseeworld",
        header: "Travel Website",
        comment:
            "Made using vanilla JS. Implemented the function wich changing language localy. Аilled the pages with video and photo content",
    },
    {
        src: mySecondWS,
        alt: "web site logo",
        href: "https://nikolai-web.dev/",
        href2: "https://github.com/NaumenkoN/My_Personal_website",
        header: "Personal",
        comment: "My main website about me as a web-developer. Made with React.",
    },
    {
        src: strategy,
        alt: "web site logo",
        href: "https://strategy-game.fun/",
        href2: "https://github.com/NaumenkoN/strategy_game",
        header: "Strategy-Game",
        comment:
            "The big pet project on React with Redux Toolkit. The game based on Monopoly with some new features.",
    },
    {
        src: lostify,
        alt: "web site logo",
        href: "https://lostify.org/",
        href2: "For the security reasons repository of this project is private",
        header: "Lostify",
        comment:
            "The largest and most perfect real world project I have ever created. MERN stack project. Can you try it?",
    },
    {
        src: advertisement,
        alt: "web site logo",
        href: "",
        href2: "",
        header: "Whats next?",
        comment: "",
    },
];

const ProjectPage = ({ showStackPage, showMainPage, showContactsPage, underline, openNavBar, closeNavBar }) => {
    return (
        <div>
            <Background underline={underline} />
            <NavBar
                showContactsPage={showContactsPage}
                showStackPage={showStackPage}
                showMainPage={showMainPage}
                underline={underline}
                openNavBar={openNavBar}
            />
            <div className={styles.grid} onClick={closeNavBar}>
                {dataProjects.map((site, index) => {
                    return (
                        <Project
                            src={site.src}
                            alt={site.alt}
                            href={site.href}
                            header={site.header}
                            key={index}
                            comment={site.comment}
                            href2={site.href2}
                            priv={index === 4 ? true : false}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ProjectPage;

import styles from "./Contacts.module.css";

const Contacts = ({ modal, hideContactsPage, instaIcon, gitIcon, gmailIcon, animateClose, linkedInIcon }) => {
    return (
        <div className={modal ? "" : styles.hidden}>
            <div onClick={hideContactsPage} className={styles.modal}></div>
            <div className={`${styles.backdrop} ${animateClose && styles.animateclose}`}>
                <button onClick={hideContactsPage} className={`${styles["close-modal__button"]} `}>
                    X
                </button>
                <div className={styles.contacts}>
                    <div className={styles.contact}>
                        <img className={styles["contacts-icons"]} src={linkedInIcon} alt="#"></img>
                        <a
                            className={styles["contact-text"]}
                            href="https://www.linkedin.com/in/nikolai-naumenko-611735269/"
                        >
                            {" "}
                            <h2>LinkedIn</h2>
                        </a>
                    </div>
                    <div className={styles.contact}>
                        <img className={styles["contacts-icons"]} src={instaIcon} alt="#"></img>
                        <a className={styles["contact-text"]} href="https://www.instagram.com/ka_lyamba">
                            {" "}
                            <h2>Instagram</h2>
                        </a>
                    </div>
                    <div className={styles.contact}>
                        <img className={styles["contacts-icons"]} src={gitIcon} alt="#"></img>
                        <a className={styles["contact-text"]} href="https://github.com/NaumenkoN">
                            {" "}
                            <h2>Git Hub</h2>
                        </a>
                    </div>
                    <div className={styles.contact}>
                        <img className={styles["contacts-icons"]} src={gmailIcon} alt="#"></img>
                        <a href="mailto:kolia.aleko@gmail.com" className={styles["contact-text"]}>
                            {" "}
                            <h2>Gmail</h2>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;

import React from "react";
import NavBar from "../main/NavBar";
import Background from "../main/Background";
import StackItems from "./StackItems";
import StackItem from "./StackItem";

import imgHtml from "../../img/icons/html-5.png";
import imgCss from "../../img/icons/css.png";
import imgJs from "../../img/icons/java-script.png";
import imgReact from "../../img/icons/react.png";
import imgGit from "../../img/icons/github.png";
import imgBootStrap from "../../img/icons/bootstrap.png";
import imgNodeJs from "../../img/icons/nodejs.png";
import imgSQL from "../../img/icons/sql.png";
import imgNextGoals from "../../img/icons/Next_goals.png";
import imgTailwindCSS from "../../img/icons/tailwind.png";
import imgExpressJS from "../../img/icons/express-js.png";
import imgMongoDB from "../../img/icons/mongodb.png";

const Stack = ({ showProjectsPage, showMainPage, showContactsPage, underline, openNavBar, closeNavBar }) => {
    let data = [
        {
            src: imgJs,
            alt: "JS icon",
            comment:
                "All basics. Many new conception of JS ES6+: asynchronous JS, destructuring, API, DOM and more.",
        },
        {
            src: imgHtml,
            alt: "html icon",
            comment: "All basics conceptions of HTML5 to create semantic right websites.",
        },
        {
            src: imgCss,
            alt: "css icon",
            comment:
                "All basics conceptions of CSS3. Includs Grid, FlexBox, animation, responsive design and more.",
        },

        {
            src: imgReact,
            alt: "React icon",
            comment:
                "All basics and many advanced concepts of Redux. Includs useState, useEffect, useMemo, useCallback, useRef, usePortal, useContext, props, Redux ToolKit, routing and many react libraries.",
        },
        {
            src: imgGit,
            alt: "git icon",
            comment: "Almost all of my projects are done using GIT version control.",
        },
        {
            src: imgBootStrap,
            alt: "bootstrap icon",
            comment:
                "I can connect the framework and start using it. None of my projects are made using bootstrap, but some educational projects are.",
        },
        {
            src: imgTailwindCSS,
            alt: "tailwind icon",
            comment:
                "I had the pleasure of working with TailwindCSS with my latest project. All basic concepts, including responsive design, are successfully fixed.",
        },
        {
            src: imgNodeJs,
            alt: "nodejs icon",
            comment:
                "Wonderful experience with node.js with my project lostify.org. Working with a database, frameworks, connecting many libraries, working with mail, security and much more.",
        },
        {
            src: imgExpressJS,
            alt: "expressjs icon",
            comment: "For me, ExpressJS and Node.js are an inextricable combination. So yes, I use it.",
        },
        {
            src: imgMongoDB,
            alt: "modgoDB icon",
            comment:
                "Good experience working with MongoDB in conjunction with the Mongoose framework. Model creation, CRUD operations and more.",
        },
        {
            src: imgSQL,
            alt: "sql icon",
            comment:
                "I took a large course on PostgreSQL, I worked with practical tasks, but there was no time to create my own project based on SQL.",
        },
        {
            src: imgNextGoals,
            alt: "next icon",
            comment: "My next goals to learn is: NextJS, SocketIO, GraphQL, SASS or LESS, WebPack, WordPress",
        },
    ];

    return (
        <div>
            <Background underline={underline} />
            <NavBar
                showContactsPage={showContactsPage}
                showProjectsPage={showProjectsPage}
                showMainPage={showMainPage}
                underline={underline}
                openNavBar={openNavBar}
            />
            <StackItems>
                {data.map((item, index) => {
                    return (
                        <StackItem
                            closeNavBar={closeNavBar}
                            key={index}
                            src={item.src}
                            alt={item.alt}
                            comment={item.comment}
                        />
                    );
                })}
            </StackItems>
        </div>
    );
};

export default Stack;
